@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Source Sans 3", sans-serif;
  height: 100%;
}

.alert-success {
  color: #4bb543;
}

.alert-danger {
  color: #bb2124;
}
